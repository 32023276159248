import { z } from "zod";

const environmentSchema = z.enum(["production", "staging", "development", "demo"]);
export type Environment = z.infer<typeof environmentSchema>;

const gilityEnvParsed = environmentSchema.safeParse(
  process.env.GILITY_ENV ?? process.env.NEXT_PUBLIC_GILITY_ENV ?? "development",
);

const gilityEnvRaw: Environment = gilityEnvParsed.success ? gilityEnvParsed.data : "development";

const isENVProd = gilityEnvRaw === "production";
const isENVDev = gilityEnvRaw === "development";
const isENVStaging = gilityEnvRaw === "staging";
const isENVDemo = gilityEnvRaw === "demo";

export const isDev = process.env.NODE_ENV === "development";

const validator: Record<Environment, () => boolean> = {
  development: () => isENVDev || isDev,
  demo: () => isENVDemo,
  staging: () => isENVStaging,
  production: () => isENVProd,
};

const friendlyEnvMap: Record<Environment, string> = {
  development: "dev",
  demo: "demo",
  staging: "alpha",
  production: "prod",
};

export const gilityEnv = {
  ...validator,
  raw: gilityEnvRaw,
  friendly: friendlyEnvMap[gilityEnvRaw],
  dev: validator.development,
  is: (array: Environment[]) => array.some((env) => validator[env]()),
};
