declare const window: Window & { dataLayer?: Record<string, unknown>[] };

export const pageview = ({
  url,
  userId = undefined,
  userType = null,
}: {
  url: string;
  userId?: string;
  userType?: string | null;
}) => {
  window.dataLayer?.push({
    event: "pageview",
    page: url,
    user_id: userId,
    userType,
  });
};

export const hsForm = (
  formName: string,
  privacy: boolean,
  email?: string | null,
  dimensioni_azienda?: string | null,
) => {
  window.dataLayer?.push({
    event: `hs_form_${formName}`,
    privacy: privacy ? "yes" : "no",
    email,
    ...(dimensioni_azienda && { dimensioni_azienda }),
  });
};

export const event = (name: string) => {
  window.dataLayer?.push({
    event: name,
  });
};

export const userInfo = ({
  userId = undefined,
  userType = null,
}: {
  userId?: string;
  userType?: string | null;
}) => {
  window.dataLayer ||= [];
  window.dataLayer.push({
    user_id: userId,
    userType,
  });
};
